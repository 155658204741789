.mainBackground{
  background: linear-gradient(to right, #2D7E83, #03494B);
  height: 100vh;
}
.mainCol {
  padding: 12px;
  &:last-child {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
}

// medium screen
@media(max-width:1200px){
  .mainCol:first-child{
    padding-left: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
}
// small screen
@media(max-width:900px){
  .mainCol:first-child{
    position: absolute;
    z-index: 10;
    left: -100%;
    width:200px;
    transition: all 500ms ease;
    &.show{
      left:0%
    }
  }

  .mainCol:last-child {
    max-width: 100%;
    flex-basis: 100%;
  }
}

// greater small screen
@media(min-width:901px){
  .mainCol:last-child {
    padding-left: 0px;
  }
}