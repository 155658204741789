.dropdown {
  z-index: 1302 !important;
  button {
    padding: 0px 0px !important;
    width: auto !important;
    text-align: right !important;
  }
  .MuiMenuItem-root svg {
    margin-right: 10px;
  }
}
