.label{
    // margin-left: 10px !important;
    margin-bottom: 5px !important;
    color: black;
}
.searchField {
    width: 85%
}

.row{
 display: flex;
 flex-direction: row;
 align-items:flex-end;
 justify-content: center;   
 width: 85%;
height: 100%;

}


@media (max-width:1200px) {
    .searchField {
        width: 80%
    }
}

@media (max-width:900px) {
    .searchField {
        width: 100%
    }
}