@import "variables";
.sidebar {
  min-height: 97vh;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0px 12px 32px rgba(162, 171, 200, 0.15);
  border-radius: 4px;
  padding: 30px 9px;
  position:relative;
  .logo-container {
    img {
      width: 100%;
    }
    img.logo-underline {
      margin: 0px 0px;
    }
  }
  .menu {
    padding-left: 0px;
    list-style-type: none;
    li a {
      padding: 11px;
      display: flex !important;
      align-items: center;
      text-transform: capitalize;
      &.active {
        background: #ffffff;
        border: 1px solid rgba(188, 192, 221, 0.25);
        box-sizing: border-box;
        box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        .navIcon {
          svg {
            color: white;
            padding: 8px;
            background: #2D7E83;
            box-sizing: content-box;
            border-radius: 5px;
          }
        }
        .navText {
          color: #000;
        }
      }
      svg {
        margin-right: 10px;
        color: $primary-icon-color;
      }
    }
  }

  a {
    color: rgb(43, 36, 36);
    text-decoration: none;
  }

  &.collapse {
    .menu li a {
      display: block;
      padding: 0px;
      margin-bottom: 20px;
      flex-direction: column;
      justify-content: center;
      &.active {
        opacity: 1;
        box-shadow: none;
        border: none;
        .navIcon {
          border-radius: 6px;
        }
        .navText {
          color: #000;
        }
      }
      svg {
        margin: 0px;
      }
      span {
        display: block;
      }
    }
  }
}
// medium screen
@media(max-width:1200px){
  .sidebar{
    padding: 30px 5px;
    border-radius: 0px;
    height: 100vh;
    .menu li a{
      padding: 5px;
      &.active{
        .navIcon svg{
          font-size: 10px;
          padding: 5px;
        }
      }
      span:last-child{
        font-size:10px;
      }
    }
  }
}
// .sidebar .toggleButton{
//   display: block;
// }

@media(min-width:900px){
  .sidebar .toggleButton{
    display: none;
  }
}