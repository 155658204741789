.card-container{
    background-color: #F5F5F5;
    display: flex;
    flex-direction: row;
    width: 80%;
    height: 20vh;
    align-items: center;
    border-radius: 12px;
    padding: 24px 16px;

}

.profile-image-conatiner{
    border-radius: 8px;
    background-color: yellow;
}

.left-align{
 display: flex;
 flex-direction: column;
 align-items:flex-start;
margin: 0 20px;
}

.profile-text-conatiner{
//  display: flex;
//  flex-direction: column;
//  align-items:flex-start;
 padding: 12px 5px;
}

.teacher-image{
    width: 160px;
    height: 160px;
    border-radius: 12px;
}

.profile-btn{
    width:180px;
}