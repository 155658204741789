.header {
    background: #f2f2f2;
    margin: 3px !important;
    border-radius: 5px;
}
.body {
    background: #eef3ff;
    margin: 3px !important;
    border-radius: 5px;
}

.delete-button {
    display: flex;
      flex-direction: row-reverse;
  }