.desktopHeader {
  // margin-bottom: 15px;
  height: 10vh;
  .MuiPaper-root {
    background: transparent;
    box-shadow: none;
  }
  .breadcrumb-text {
    text-transform: capitalize;
  }
  .toggleButton button{
    display: none;
  }
}

nav {
  .MuiBreadcrumbs-separator,
  p {
    color: white;
  }
}

.user-icon-header {
  margin-right: 0px !important;
}

.header-person-details-list {
  margin: 0 !important;
  padding: 10px !important;
  background-color: #e4ebf9;
  border-radius: 10px;
  min-width: 300px;
  li {
    background-color: rgb(238, 238, 238);
    padding: 6px 10px;
    margin: 5px 0 0 0;
    gap: 10px;
    color: rgb(47, 47, 47);
  }
  .header-person-details-container {
    display: flex;
    margin-bottom: 12px;
    .header-person-image {
      img {
        width: 40px;
        border-radius: 80px;
      }
    }
    .header-person-details {
      margin-left: 5px;
      h6 {
        margin: 0;
        font-size: 12px;
      }
      p {
        margin: 2px 0 0 0;
        font-size: 12px;
        color: gray;
      }
      button {
        color: rgb(72, 67, 67);
        text-transform: capitalize;
        padding: 0 15px;
        font-size: 10px;
        margin-top: 15px;
        font-weight: 600;
        width: 100%;
      }
    }
  }
}
@media(max-width:900px){
  .desktopHeader .toggleButton{
    display: flex;
  }
  .desktopHeader .toggleButton button{
    display: block;
  }
}