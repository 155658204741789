.dragOver {
  background-color: rgba(227, 251, 255, 1) !important;
  border: 3px dotted aliceblue !important;
}
.uploadImage {
  height: 5vh;
}
.uploadArea{
  display: flex;
  justify-content: center;
  margin: 10px;
}