
#login-background {
  background-image: url("../assets/images/bg.png");
  /* background-image: url("../assets/images/anton-sukhinov-_C2A8THeKKs-unsplash.jpg"); */
  height: 100vh;
  width: 100%;
  /* margin: 15px; */
  background-size: cover;
  background-repeat: no-repeat;
  /* border-radius: 15px; */
  background-position: center;
}
.FormControl {
  margin-bottom: 15px;
  .label {
    position: relative;
    top: 0;
  }
}
.FormControl .login-page {
  height: 100vh;
  width: 100% !important;
  margin: 0px !important;
  padding: 0px !important;
}
.login-form-container {
  width: 75%;
  margin: auto;
  padding-right: 40px;
  .logo {
    display: block;
    width: 25%;
    margin: 0 auto;
  }
  .login-heading {
    font-family: "Lato";
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: center;
    margin: 50px 0px 40px 0px;
  }
  .loginForm {
    margin-bottom: 15%;
    .login-input {
      border: 1px solid rgba(0, 0, 0, 0.23);
      border-radius: 8px;
    }
  }
  .sign-in-text {
    font-family: "Lato";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    margin: 30px 0px;
    color: #2d3748;
  }
  .link-txt {
    color: #2cc1d6;
    cursor: pointer;
    font-family: Helvetica;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: right;
    text-decoration: underline;
    margin: 10px 8px;
    display: inline-block;
  }
  .switch-label {
    color: #2d3748 !important;
    font-weight: 500 !important;
    margin-left: 5px;
  }
  .login-row-container {
    .MuiGrid-item {
      margin-top: 10px;
    }
  }
}
.login-left {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.person-image {
  position: absolute;
  width: 80%;
}
@media (max-width:768px) {
  .login-form-container{
    width: 90%;
    margin:20px auto;
    padding:0px;
  }
  .login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
}